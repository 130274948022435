<template>
  <div class="overview-lookalikes">
    <div class="text-h6 font-weight-bold mb-4">
      Influencer Brand Affinity
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          We determine interests by analyzing posts for caption texts, @mentions, #hashtags and location tags.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft d-flex flex-column justify-between">
      <v-card-text class="flex-grow-1">
        <v-list dense>
          <v-list-item
            v-for="item in brands.slice(0, 4)"
            :key="item.id"
          >
            <v-list-item-avatar
              rounded="0"
              size="30"
            >
              <v-img contain :src="proxyUrl(item.logo)" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          small
          @click="shouldShowDialog = true"
        >
          View More
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- the dialog table -->
    <v-dialog
      v-model="shouldShowDialog"
      width="600"
      scrollable
    >
      <v-card>
        <v-card-title>
          Influencer Brand Affinity
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-list dense>
            <v-list-item
              v-for="item in brands"
              :key="item.id"
            >
              <v-list-item-avatar
                rounded="0"
                size="30"
              >
                <v-img contain :src="proxyUrl(item.logo)" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "InfluencerBrandAffinity",

  // Accept incoming data from parent
  props: {
    userData: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    shouldShowDialog: false
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get the brands for the list view
     *
     * @returns {Array}
     */
    brands() {
      return this.userData.data.user_profile.brand_affinity.map(item => ({
        ...item,
        logo: `${process.env.VUE_APP_IMAGE_PROXY_BASE}${item.id}&type=brand-logo`
        // logo: `${this.env.VUE_APP_API_BASE_URL}/storage/brand-logos/${item.id}.png`
      }))
    }
  }
}
</script>
